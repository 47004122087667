import request from 'utils/request';
const api = {
  IP_PROXY_ADD: '/api/admin/v3/ipProxy/add',
  IP_PROXY_PAGE: '/api/admin/v3/ipProxy/list',
  IP_PROXY_DELETES: '/api/admin/v3/ipProxy/delete',
};

// ipProxy
export function ipProxyPage(params) {
  return request({
    url: api.IP_PROXY_PAGE,
    method: 'get',
    params,
  });
}

export function ipProxyDelete(id) {
  return request({
    url: api.IP_PROXY_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function ipProxyAdd(data) {
  return request({
    url: api.IP_PROXY_ADD,
    method: 'post',
    data,
  });
}
